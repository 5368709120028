import { ContentLoaderInfoQuery } from "@modules/graphql/queries.generated"
import { errorMessages } from "./index"
import { GatsbyAnalytcisEvent } from "@modules/analytics/types"

// @todo export these types from Gatsby and import here instead of copying the types
type INodeManifestPage = {
  path?: string
}
type FoundPageBy =
  | `ownerNodeId`
  | `filesystem-route-api`
  | `context.id`
  | `queryTracking`
  | `none`
type INodeManifestOut = {
  page: INodeManifestPage
  node: {
    id: string
  }
  foundPageBy: FoundPageBy
  pageDataDigest?: string
}

const startTime = Date.now()

/**
 * Used to check if a redirect url exists before we redirect the user.
 */
export const doesUrlExist = async (url: string): Promise<boolean> => {
  console.info(`requesting /api/does-url-exist with url ${url}`)
  const response = await fetch(`/api/does-url-exist`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      url,
    }),
  })

  const responseJson = await response.json()

  return !!responseJson?.exists
}

let ip = ``
let attemptedToFetchIp = false

export const getUserIp = async () => {
  if (!attemptedToFetchIp) {
    attemptedToFetchIp = true
    try {
      // This API is free forever with unlimited requests but if this fails in the future we will still get the user IP in our serverless fn in `/api/does-page-data-digest-match`. Using this is more accurate for local dev though since /api/does-page-data-digest-match thinks our IP is 127.0.0.1
      const ipResponse = await fetch(`https://api.ipify.org`)

      ip = await ipResponse.text()

      return ip
    } catch (e) {
      console.info(`failed to find users IP`)
      return null
    }
  } else {
    return ip
  }
}

export const doesPageDataDigestMatch = async ({
  manifest,
  frontendUrl,
}: {
  manifest: INodeManifestOut
  frontendUrl: string
}): Promise<boolean> => {
  const userIp = await getUserIp()

  console.info(`requesting /api/does-page-data-digest-match`)
  const response = await fetch(`/api/does-page-data-digest-match`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      manifest,
      frontendUrl,
      ip: userIp,
    }),
  })

  const responseJson = await response.json()

  return !!responseJson?.pageDataDigestsMatch
}

let urlExists = false
let checkPageDataDigestCounter = 0
const maxPageDataChecks = 6

/**
 * Waits for the redirect url to be available
 * then checks the manifest.pageDataDigest against the sites page-data.json to make sure the page-data.json has finished deploying and propagating
 */
export const waitForPageDataToBeDeployed = async ({
  redirectUrl,
  manifest,
  frontendUrl,
}: {
  redirectUrl: string
  manifest?: INodeManifestOut
  frontendUrl: string
}): Promise<true | undefined> => {
  urlExists = urlExists || (await doesUrlExist(redirectUrl))

  if (urlExists) {
    console.info(`url ${redirectUrl} exists!`)

    if (!manifest?.pageDataDigest) {
      console.info(`No page data digest to check.`)
      // if we don't have a pageDataDigest we don't need to check page-data.json
      return true
    }

    console.info(`checking if page data digest matches`)
    // if we have a pageDataDigest
    // check if page data digest matches
    const digestsMatch = await doesPageDataDigestMatch({
      manifest,
      frontendUrl,
    })

    if (digestsMatch) {
      console.info(`page data digest matches! changes have been deployed`)
      return true
    } else {
      console.info(
        `page data digest doesn't match. changes have not been deployed`
      )
    }

    // if not, wait .5 seconds
    await new Promise(resolve => setTimeout(resolve, 500))

    // if we've already checked maxPageDataChecks times then redirect anyway.
    if (checkPageDataDigestCounter >= maxPageDataChecks) {
      return true
    } else {
      // otherwise increase counter and try again
      checkPageDataDigestCounter++
      return await waitForPageDataToBeDeployed({
        redirectUrl,
        manifest,
        frontendUrl,
      })
    }
  } else {
    // The redirectUrl isn't returning a 200 yet. This is for brand new pages that didn't previously exist.
    console.info(`url ${redirectUrl} not found. Waiting 100ms and re-checking.`)
    await new Promise(resolve => setTimeout(resolve, 100))
    return await waitForPageDataToBeDeployed({
      redirectUrl,
      manifest,
      frontendUrl,
    })
  }
}

/**
 * Fetches the corresponding node manifest file from the users Gatsby site
 */
export const fetchNodeManifest = async ({
  manifestId,
  siteId,
  sourcePluginName,
  refetch,
  frontendUrl,
  setLoaderError,
  trackAction,
}: {
  manifestId: string
  siteId: string | null
  sourcePluginName: string
  refetch: () => void
  frontendUrl: string
  setLoaderError: (arg: boolean) => void
  trackAction: (params: { eventType: string } & GatsbyAnalytcisEvent) => void
}): Promise<{
  manifest?: INodeManifestOut
  error?: { message: string; code: number }
  shouldPoll: boolean
}> => {
  const response = await fetch(`/api/fetch-node-manifest`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sourcePluginName,
      manifestId,
      siteId,
      frontendUrl,
    }),
  })

  const { manifest, error } = await response.json()

  if (manifest) {
    const { page, foundPageBy } = manifest

    console.info(`found manifest after ${Date.now() - startTime}ms`, {
      manifest,
    })

    if (page?.path) {
      const urlAvailableTimeout = setTimeout(() => {
        // if the url isn't available within 30 seconds, set an error
        setLoaderError(true)
      }, 100000)

      const redirectUrl = `${frontendUrl}${page.path}`
      console.info(`waiting for ${redirectUrl} to be available`)
      const pageIsReady = await waitForPageDataToBeDeployed({
        redirectUrl,
        manifest,
        frontendUrl,
      })

      if (pageIsReady) {
        clearTimeout(urlAvailableTimeout)

        const loadingDuration = Date.now() - startTime

        trackAction({
          eventType: `TRACK_EVENT`,
          name: `Content Sync Success`,
          uiSource: `Content Sync`,
          siteId: siteId || "",
          duration: loadingDuration,
          sourcePluginName,
        })
        console.info(`redirecting to ${redirectUrl} after ${loadingDuration}ms`)

        window.location.replace(redirectUrl + `?gt=${loadingDuration}`)

        return { shouldPoll: false, manifest }
      }
    } else if (foundPageBy === `none`) {
      // a page was not created for the node being previewed
      return { shouldPoll: false, manifest }
    }
  } else if (error) {
    return { shouldPoll: false, error }
  } else {
    refetch()
  }

  return { shouldPoll: true }
}

type PollArguments = {
  apolloData?: ContentLoaderInfoQuery
  misconfiguredUrl: boolean
  loaderError: boolean
  pollingHasStarted: boolean
  manifestId: string
  sourcePluginName: string
  siteId: string
  frontendUrl: string | false
  pollCount: number
  refetch: () => void
  waitThenTriggerNextPoll: () => void
  setErrorMessage: (arg: string) => void
  setLoaderError: (arg: boolean) => void
  trackAction: (params: { eventType: string } & GatsbyAnalytcisEvent) => void
}

/**
 * The polling fn for the ContentLoader component to poll for node manifest files for the users Gatsby site
 */
export const poll = (pollArgs: PollArguments): void => {
  const {
    misconfiguredUrl,
    loaderError,
    pollingHasStarted,
    manifestId,
    sourcePluginName,
    pollCount,
    apolloData,
    siteId,
    refetch,
    frontendUrl,
    waitThenTriggerNextPoll,
    setErrorMessage,
    setLoaderError,
    trackAction,
  } = pollArgs

  // if any of these are true we won't continue polling in this invocation of poll()
  if (misconfiguredUrl || loaderError || !pollingHasStarted) {
    return
  }

  if (manifestId && sourcePluginName && frontendUrl) {
    console.info(
      `#${pollCount} poll for manifest at ${apolloData?.contentLoaderInfo?.previewUrl}/__node-manifests/${sourcePluginName}/${manifestId}.json`
    )
    fetchNodeManifest({
      manifestId,
      siteId,
      sourcePluginName,
      refetch,
      frontendUrl,
      setLoaderError,
      trackAction,
    })
      .then(({ shouldPoll, error, manifest }) => {
        if (shouldPoll) {
          return waitThenTriggerNextPoll()
        }

        if (manifest?.foundPageBy === `none`) {
          console.info(
            `Gatsby site didn't create a page for the node that's being loaded`
          )
          setErrorMessage(errorMessages.noPageFound)
          setLoaderError(true)
          return
        }

        const is404 =
          error?.message?.includes(`invalid json response body`) ||
          error?.code === 404

        if (is404) {
          // 404's may just mean that the build hasn't finished.
          refetch()
          waitThenTriggerNextPoll()
          console.info(
            `Manifest request 404'd (might not be available yet, rechecking).`
          )
        } else if (error) {
          console.info(`Manifest request errored.`)
          console.error(1, error)
          setLoaderError(true)
        }
      })
      .catch(e => {
        console.info(`Manifest Gatsby function request errored.`)
        console.error(2, e)
        setLoaderError(true)
      })
  }
}
